import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512.000000 512.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
          
          <path d="M2211 2456 c-9 -10 12 -120 89 -475 55 -254 100 -464 100 -467 0 -2
        18 -4 40 -4 37 0 40 2 35 23 -16 75 -139 637 -166 762 -17 83 -36 156 -42 163
        -13 16 -42 15 -56 -2z"/>
        <path d="M2853 2458 c-6 -7 -45 -175 -88 -373 -43 -198 -88 -406 -100 -462
        -13 -56 -20 -104 -17 -107 3 -4 20 -6 38 -6 25 0 33 5 37 23 3 12 49 222 102
        466 99 459 100 471 55 471 -10 0 -22 -6 -27 -12z"/>
        <path d="M2240 1355 l0 -45 160 0 160 0 0 45 0 45 -160 0 -160 0 0 -45z"/>
        <path d="M2240 1195 l0 -45 160 0 160 0 0 45 0 45 -160 0 -160 0 0 -45z"/>
        <path d="M2405 968 c12 -61 81 -128 131 -128 23 0 24 3 24 80 l0 80 -81 0 -81
        0 7 -32z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
